import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import AuthService from '../services/AuthService';
import './LoginComponent.css';

const logos = [
  { src: '/images/carbon-accounting-svgrepo-com.svg', alt: 'Carbon' },
  { src: '/images/digital-id-svgrepo-com.svg', alt: 'Digital' },
  { src: '/images/earth-9-svgrepo-com.svg', alt: 'Earth' },
  { src: '/images/recycle-svgrepo-com.svg', alt: 'Recycle' },
  { src: '/images/recycling-water-svgrepo-com.svg', alt: 'Water' },
];

const ResetPasswordComponent = () => {
  const { token } = useParams();
  const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    try {
      if (password !== password2) {
        setError('Sifre hatalı. Lütfen tekrar deneyin.');
        return;
      }
      await AuthService.resetPassword(token, password);
      setIsSubmitted(true);
    } catch (error) {
      setError('Sifre kaydedilirken bir hata oluştu. Lütfen tekrar deneyin.');
    }
  };

  const routeToLogin = (e) => {
    e.preventDefault();
    navigate("/login");
  };

  return (
    <div className="login-container">
      <div className="background">
        {logos.map((logo, index) => (
          <div key={index} className={`icon-holder icon-holder${index + 1}`}>
            <img src={logo.src} alt={logo.alt} />
          </div>
        ))}
      </div>
      <div className="semi-transparent-container"></div>
      <div className="login-card">
        <img src="/images/rens_logo.svg" alt="Your logo" className="logo" />
        
        {!isSubmitted ? (
          <>
            <form onSubmit={handleSubmit}>
              <div className="input-group">
                <label htmlFor="password">Yeni Şifre Giriniz</label>
                <input
                  type="password"
                  id="password"
                  placeholder="********"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
              <div/>
              <div className="input-group"></div>
                 <input
                  type="password"
                  id="password2"
                  placeholder="********"
                  value={password2}
                  onChange={(e) => setPassword2(e.target.value)}
                  required
                />
              <div/>
              </div>
              {error && <p style={{ color: '#ff6b6b', textAlign: 'center', marginBottom: '20px' }}>{error}</p>}
              <button type="submit" className="hero-button">
                Yeni Şifreyi Kaydet
              </button>
            </form>
            <p className="register-prompt">
              <a href="/login" onClick={routeToLogin}>Giriş sayfasına dön</a>
            </p>
          </>
        ) : (
          <div style={{ textAlign: 'center', color: '#fff' }}>
            <h3 style={{ marginBottom: '20px' }}>Yeni Şifre Kaydedildi!</h3>
            <button onClick={routeToLogin} className="hero-button">
              Giriş Sayfasına Dön
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default ResetPasswordComponent;