import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AuthService from '../services/AuthService';
import './LoginComponent.css';
import emailjs from '@emailjs/browser';

const logos = [
  { src: '/images/carbon-accounting-svgrepo-com.svg', alt: 'Carbon' },
  { src: '/images/digital-id-svgrepo-com.svg', alt: 'Digital' },
  { src: '/images/earth-9-svgrepo-com.svg', alt: 'Earth' },
  { src: '/images/recycle-svgrepo-com.svg', alt: 'Recycle' },
  { src: '/images/recycling-water-svgrepo-com.svg', alt: 'Water' },
];

const ForgotPasswordComponent = () => {
  const [email, setEmail] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    try {
      // Bu kısmı backend servisi hazır olduğunda güncelleyeceğiz
      await AuthService.forgotPassword(email);
      setIsSubmitted(true);
    } catch (error) {
      setError('E-posta gönderilirken bir hata oluştu. Lütfen tekrar deneyin.');
    }
  };

  const routeToLogin = (e) => {
    e.preventDefault();
    navigate("/login");
  };

  return (
    <div className="login-container">
      <div className="background">
        {logos.map((logo, index) => (
          <div key={index} className={`icon-holder icon-holder${index + 1}`}>
            <img src={logo.src} alt={logo.alt} />
          </div>
        ))}
      </div>
      <div className="semi-transparent-container"></div>
      <div className="login-card">
        <img src="/images/rens_logo.svg" alt="Your logo" className="logo" />
        
        {!isSubmitted ? (
          <>
            <form onSubmit={handleSubmit}>
              <div className="input-group">
                <label htmlFor="email">E-mail</label>
                <input
                  type="email"
                  id="email"
                  placeholder="username@gmail.com"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>
              {error && <p style={{ color: '#ff6b6b', textAlign: 'center', marginBottom: '20px' }}>{error}</p>}
              <button type="submit" className="hero-button">
                Şifre Sıfırlama Bağlantısı Gönder
              </button>
            </form>
            <p className="register-prompt">
              <a href="/login" onClick={routeToLogin}>Giriş sayfasına dön</a>
            </p>
          </>
        ) : (
          <div style={{ textAlign: 'center', color: '#fff' }}>
            <h3 style={{ marginBottom: '20px' }}>E-posta Gönderildi!</h3>
            <p style={{ marginBottom: '30px' }}>
              Şifre sıfırlama bağlantısı {email} adresine gönderildi. 
              Lütfen e-posta kutunuzu kontrol edin.
            </p>
            <button onClick={routeToLogin} className="hero-button">
              Giriş Sayfasına Dön
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default ForgotPasswordComponent;