import React, { useState, useMemo, useEffect } from 'react';
import {category_map} from "../assets/categories";
import SearchComponent from "./SearchComponent";

const EditItemModal = ({ item, onSave, onClose, setItem }) => {

  const handleChange = (e) => {
    const { name, value } = e.target;
    setItem({
      ...item,
      [name]: value,
    });
  };

  useEffect(() => {
      setItem({
        ...item,
        "category2": Object.keys(subCategories)[0] || "",
        "category3": Object.keys(subSubCategories)[0] || "",
      });
  }, [item.category1]);

  const parseCategoryKey = (key) => parseInt(key, 10);
  const sortCategoryKeys = (keys) => {
    return keys
        .map(parseCategoryKey) // Convert to integers
        .filter((num) => !isNaN(num)) // Filter out any NaN values
        .sort((a, b) => a - b) // Sort numerically
        .map((num) => num.toString().padStart(2, '0')); // Convert back to strings with leading zeros if needed
  };

  // Extract main categories
  const mainCategories =useMemo(() => {
    return sortCategoryKeys(
      Object.keys(category_map).filter((key) => key.length === 2)
    ).reduce((obj, key) => {
      obj[key] = category_map[key];
      return obj;
    }, {});
  }, []);


  // Extract subcategories based on selected main category
  const subCategories = useMemo(() => {
    return Object.keys(category_map)
      .filter((key) => key.startsWith(item.category1) && key.length === 4)
      .reduce((obj, key) => {
        obj[key] = category_map[key];
        return obj;
      }, {});
  }, [item.category1]);

  // Extract sub-subcategories based on selected subcategory
  const subSubCategories = useMemo(() => {
    return Object.keys(category_map)
      .filter((key) => key.startsWith(item.category2) && key.length > 4)
      .reduce((obj, key) => {
        obj[key] = category_map[key];
        return obj;
      }, {});
  }, [item.category2]);

  const getItemDate =() => {
    return new Date(item.date_of_demanded_pickup);
  }

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
      <div className="bg-[#0F4A46] p-6 rounded-lg shadow-lg">
        <h2 className="text-2xl text-white font-bold mb-4">Edit Item</h2>
        <label className="block mb-2">
          Title:
          <input
              type="text"
              name="title"
              value={item.title}
              onChange={handleChange}
              className="w-full p-2 border border-gray-300 rounded"
          />
        </label>
        <label className="block mb-2">
          Category 1:
          <select
              name="category1"
              value={item.category1}
              onChange={handleChange}
              className="w-full p-2 border border-gray-300 rounded"
          >
            {Object.entries(mainCategories)
                .sort((a, b) => parseInt(a[0]) - parseInt(b[0])) // Sort categories numerically
                .map(([key, value]) => (
                    <option className="text-black" key={key} value={key}>{value}</option>
                ))
            }
          </select>
        </label>
        <label className="block mb-2">
          Category 2:
          <select
              name="category2"
              value={item.category2}
              onChange={handleChange}
              className="w-full p-2 border border-gray-300 rounded"
          >
            {Object.entries(subCategories)
                .sort((a, b) => parseInt(a[0]) - parseInt(b[0])) // Sort categories numerically
                .map(([key, value]) => (
                    <option className="text-black" key={key} value={key}>{value}</option>
                ))
            }
          </select>
        </label>
        <label className="block mb-2">
          Category 3:
          <select
              name="category3"
              value={item.category3}
              onChange={handleChange}
              className="w-full p-2 border border-gray-300 rounded"
          >
            {Object.entries(subSubCategories)
                .sort((a, b) => parseInt(a[0]) - parseInt(b[0])) // Sort categories numerically
                .map(([key, value]) => (
                    <option className="text-black" key={key} value={key}>{value}</option>
                ))
            }
          </select>
        </label>
        <label className="block mb-2">
          Description:
          <textarea
              name="description"
              value={item.description}
              onChange={handleChange}
              className="w-full p-2 border border-gray-300 rounded"
          />
        </label>
        <label className="block mb-2">
          Amount:
          <input
              type="number"
              name="amount"
              value={item.amount}
              onChange={handleChange}
              className="w-full p-2 border border-gray-300 rounded"
          />
        </label>
        <label className="block mb-2">
          Requested Purchase Date:

          <input
              type="date"
              name="date_of_demanded_pickup"
              value= {
                  getItemDate().getFullYear().toString() +
                  "-" +
                  (getItemDate().getMonth() + 1).toString().padStart(2, 0) +
                  "-" +
                  getItemDate().getDate().toString().padStart(2, 0)
                }
              onChange={handleChange}
              className="w-full p-2 border border-gray-300 rounded"
          />
        </label>
        <label className="block mb-2">
          Who Handles Logistics:
          <select
              name="who_handle_logistics"
              value={item.who_handle_logistics}
              onChange={handleChange}
              className="w-full p-2 border border-gray-300 rounded"
          >
            <option className="text-black" value="seller">Seller</option>
            <option className="text-black" value="buyer">Buyer</option>
          </select>
        </label>
        <div className="flex justify-end">
          <button onClick={() => onSave(item)} className="bg-blue-500 text-white py-2 px-4 rounded mr-2">Save
          </button>
          <button onClick={onClose} className="bg-gray-500 text-white py-2 px-4 rounded">Cancel</button>
        </div>
      </div>
    </div>
  );
};

export default EditItemModal;