// src/services/AuthService.js
import axios from 'axios';

const API_URL = `${process.env.REACT_APP_API_BASE_URL}`;

const login = (email, password) => {

  return axios.post(`${API_URL}/signin`, { email, password });
};

const signUp = (signUpRequest) => {

  return axios.post(`${API_URL}/signup`, signUpRequest);
};

const forgotPassword = (email) => {

  return axios.post(`${API_URL}/forgot-password`, { email });
};

const resetPassword = (token, password) => {

  return axios.post(`${API_URL}/reset-password/${token}`, { password });
};

const logout = () => {
  sessionStorage.removeItem('user');
};

const isAuthenticated = () => {
  const token = sessionStorage.getItem('user');
  if(token !== null){
  return true;
  }
  else{
    return false;
  }
};
const getCurrentUserToken = () => {
  return localStorage.getItem('token');
};

export default {
  login,
  forgotPassword,
  resetPassword,
  signUp,
  logout,
  isAuthenticated,
  getCurrentUserToken,
};
