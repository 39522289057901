import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth } from "../contexts/AuthProvider";
import Sidebar from "../components/Sidebar";
import Header from "../components/Header";
import EmissionDataForm from '../components/EmissionDataForm';
import EmissionDataViewPage from '../components/EmissionDataViewPage';
import ReportsPage from '../components/ReportsPage';
import { motion, AnimatePresence } from 'framer-motion';
import { FaBuilding, FaUsers, FaRulerCombined, FaEnvelope, FaPhone, FaChartLine, FaArrowUp, FaArrowDown } from 'react-icons/fa';
import styles from './MarketPlace.module.css';

const RENSCO2Page = ({ screen }) => {
    const [currentView, setCurrentView] = useState('genel bakış');
    const [userDetails, setUserDetails] = useState({});
    const { token } = useAuth();
    const [emissionData, setEmissionData] = useState({});
    const [emissionDataYearly, setEmissionDataYearly] = useState([]);
    const [openProgress, setOpenProgress] = useState(false);


    useEffect(() => {
        screen ? setCurrentView(screen) : setCurrentView('genel bakış') 
    }, [screen]);

    useEffect(() => {
        fetchUserDetails();
        getEmissionCalculatorData();
    }, [token]);

    useEffect(() => {
        const yearlyData = calculateYearlyTotals();
        setEmissionDataYearly(yearlyData)
    }, [emissionData]);

    const fetchUserDetails = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/profile`, {
                headers: { 'x-access-token': token },
            });
            setUserDetails(response.data.user);
        } catch (error) {
            console.error('Error fetching profile:', error);
        }
    };

    const getEmissionCalculatorData =  async() => {
        setOpenProgress(true);
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/calculator/emission`, {
            headers: { 'x-access-token': token }
            
          });
          
          if (response.status === 200 || response.status === 201) {
            setEmissionData(response.data.data || {}); // Ensure data is not null or undefined
          } else {
            
                console.warn("Unexpected response status:", response.status);
            }
            setOpenProgress(false);
        } catch (error) {
          console.error('Error calculating emission data:', error);
          setOpenProgress(false);
        }
    }

    const calculateYearlyTotals = () => {
        const yearlyTotals = {};
        //const requestParams = { startDate: filters.usagePeriodStart, endDate: filters.usagePeriodEnd }
      
        if (emissionData && emissionData.scope1 && emissionData.scope2 && emissionData.scope3) {
            processEntries(emissionData.scope1, yearlyTotals);
            processEntries(emissionData.scope2, yearlyTotals);
            processEntries(emissionData.scope3, yearlyTotals);
        } else {
            console.warn("Emission data is incomplete or undefined.");
        }
    
        // Format result
        return Object.keys(yearlyTotals).map((year) => ({
            year: parseInt(year),
            emission: parseFloat(yearlyTotals[year].toFixed(2)),
        }));
    };

    const processEntries = (entries, yearlyTotals) => {
        entries.forEach((entry) => {
            const start = new Date(entry.startDate);
            const end = new Date(entry.endDate);
            const value = entry.calculatedValue;

            // Loop through each year in the range
            for (let year = start.getFullYear(); year <= end.getFullYear(); year++) {
                const yearStart = new Date(year, 0, 1);
                const yearEnd = new Date(year, 11, 31, 23, 59, 59);

                const rangeStart = start > yearStart ? start : yearStart;
                const rangeEnd = end < yearEnd ? end : yearEnd;

                const daysInRange =
                    (rangeEnd - rangeStart) / (1000 * 60 * 60 * 24) + 1; // Days in range
                const totalDays =
                    (end - start) / (1000 * 60 * 60 * 24) + 1; // Total days in the entry

                const proportionalValue = (value * daysInRange) / totalDays;

                if (!yearlyTotals[year]) {
                    yearlyTotals[year] = 0;
                }
                yearlyTotals[year] += proportionalValue;
            }
        });
    };

    const handleViewChange = (view) => {
        setCurrentView(view.toLowerCase());
    };

    const companyDataLabels = {
        companyName: "Şirket Adı",
        address: "Tesis Adresi",
        numberOfEmployee: "Çalışan Sayısı",
        facilitySize: "Toplam Tesis Alanı",
        companyMail: "E-mail",
        phone: "İletişim"
    };

    const companyDataIcons = {
        companyName: FaBuilding,
        address: FaBuilding,
        numberOfEmployee: FaUsers,
        facilitySize: FaRulerCombined,
        companyMail: FaEnvelope,
        phone: FaPhone
    };


    const renderOverview = () => (
        <div className="bg-[#0F4A46] rounded-2xl p-6">
            <h2 className="text-2xl font-bold mb-6 text-white">Şirket Bilgileri</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                {Object.entries(companyDataLabels).map(([key, label]) => {
                    const Icon = companyDataIcons[key];
                    const value = userDetails[key] || "Veri yok";
                    return (
                        <div key={key} className="bg-[#165853] rounded-xl p-4 relative group">
                            <h3 className="text-white font-medium text-sm mb-1">{label}</h3>
                            <p className="text-white/70 text-xs">{value}</p>
                            <Icon className="absolute right-4 top-1/2 transform -translate-y-1/2 text-white/30 text-3xl" />
                        </div>
                    );
                })}
            </div>
            <h2 className="text-2xl font-bold my-6 text-white">Emisyon Verileri</h2>
            
            {openProgress ?  <div
                className="flex justify-center items-center h-screen"
                style={{ backgroundColor: 'rgba(211, 211, 211, 0.4)' }}
            >
                <div
                    className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2"
                    style={{ borderColor: '#0F4A46' }}
                ></div>
            </div> : 
            <div className="bg-[#165853] rounded-xl p-6 overflow-hidden shadow-lg">
                <table className="w-full text-white border-separate border-spacing-y-4">
                    <thead>
                        <tr>
                            <th className="text-left pb-4 text-sm uppercase tracking-wider font-semibold">Yıl</th>
                            <th className="text-right pb-4 text-sm uppercase tracking-wider font-semibold">Emisyon (kgCO2e)</th>
                            <th className="text-right pb-4 text-sm uppercase tracking-wider font-semibold">Değişim</th>
                        </tr>
                    </thead>
                    <tbody>
                        {emissionDataYearly.map((data, index) => {
                            const change = index === 0 ? 0 : ((data.emission - emissionDataYearly[index-1].emission) / emissionDataYearly[index-1].emission * 100);
                            return (
                                <tr key={index} className="bg-[#0F4A46]/50 hover:bg-[#0F4A46] transition-colors duration-200">
                                    <td className="py-4 px-6 rounded-l-lg">{data.year}</td>
                                    <td className="text-right py-4 px-6">{data.emission.toFixed(2)}</td>
                                    <td className="text-right py-4 px-6 rounded-r-lg">
                                        {index === 0 ? (
                                            '-'
                                        ) : (
                                            <span className={`flex items-center justify-end ${change < 0 ? 'text-green-400' : 'text-red-400'}`}>
                                                {change < 0 ? <FaArrowDown className="mr-1" /> : <FaArrowUp className="mr-1" />}
                                                {Math.abs(change).toFixed(2)}%
                                            </span>
                                        )}
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                    <tfoot>
                        <tr className="bg-[#0F4A46] font-semibold">
                            <td className="py-4 px-6 rounded-l-lg">Toplam Değişim</td>
                            <td className="text-right py-4 px-6">
                                {emissionDataYearly.length >= 2 ? (emissionDataYearly[emissionDataYearly.length - 1].emission - emissionDataYearly[0].emission).toFixed(2) : '-'}
                            </td>
                            <td className="text-right py-4 px-6 rounded-r-lg">
                                {emissionDataYearly.length >= 2 ? ((emissionDataYearly[emissionDataYearly.length - 1].emission - emissionDataYearly[0].emission) / emissionDataYearly[0].emission * 100).toFixed(2) : '-'}%
                            </td>
                        </tr>
                    </tfoot>
                </table>
            </div> }
        </div>
    );

    return (
        <div style={{ display: 'flex' }}>
            <Sidebar />
            <div className="ml-64" style={{ flex: 1 }}>
            <Header userName={userDetails.name} />
                <div className="w-full p-4 bg-[#FFFFFF] min-h-screen"             
                    style={{
                        width: '100%',
                        maxWidth: '1700px',
                        margin: '0 auto',
                    }}
                >
                    <div className={styles.stepHeader}>
                        <img src="/rens.co2logo.png" alt="Rens CO2 Logo" className={styles.stepLogo} />
                    </div>
                    <div className="flex gap-2 p-4 bg-[#0F4A46] rounded-2xl mb-4 items-center">
                        {['Genel Bakış', 'Veriler', 'Raporlar', 'Hedefler'].map((item, index) => (
                            <button
                                key={index}
                                onClick={() => handleViewChange(item)}
                                className={`px-6 py-3 rounded-xl transition-colors duration-300 ${
                                    currentView === item.toLowerCase()
                                        ? 'bg-[#165853] text-white font-medium'
                                        : 'bg-[#0F4A46] text-white/70 hover:bg-[#165853]/50'
                                }`}
                            >
                                {item}
                            </button>
                        ))}
                        <button
                            onClick={() => handleViewChange('emissionData')}
                            className="ml-auto px-6 py-3 bg-[#165853] text-white rounded-xl hover:bg-[#1a6b64] transition-colors duration-300 flex items-center"
                        >
                            <FaChartLine className="mr-2" />
                            + Emisyon Verisi Ekle
                        </button>
                    </div>

                    <AnimatePresence mode="wait">
                        <motion.div
                            key={currentView}
                            initial={{ opacity: 0, y: 10 }}
                            animate={{ opacity: 1, y: 0 }}
                            exit={{ opacity: 0, y: -10 }}
                            transition={{ duration: 0.3 }}
                        >
                            {currentView === 'genel bakış' && renderOverview()}
                            {currentView === 'emissiondata' && <EmissionDataForm onClose={() => handleViewChange('Genel Bakış')} />}
                            {currentView === 'veriler' && <EmissionDataViewPage />}
                            {currentView === 'raporlar' && <ReportsPage />}
                            {currentView === 'hedefler' && <div className="text-white">Hedefler içeriği burada olacak</div>}
                        </motion.div>
                    </AnimatePresence>
                </div>
            </div>
        </div>
    );
};

export default RENSCO2Page;