import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useAuth } from "../contexts/AuthProvider";
import AWS from "aws-sdk";
import CustomMap from "../components/CustomMap";
import { category_map } from '../assets/categories';
import {
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Grid,
  Typography,
  Box,
  Card,
  CardMedia,
  Snackbar,
  Paper,
  IconButton,
  Chip,
  CircularProgress,
  Modal,
  InputAdornment,
} from "@mui/material";
import {
  CloudUpload,
  ArrowForward,
  Add,
  Description,
  Category,
  LocalShipping,
  PhotoCamera,
  LocationOn,
  RateReview,
  Edit,
  CheckCircle,
  Person,
  Business,
  SupportAgent,
  Title,
  Label,
  Scale,
  Inventory2,
  AccessTime,
  AttachMoney,
  Straighten,
  Recycling,
  Nature,
} from "@mui/icons-material";
import { useDropzone } from 'react-dropzone';
import './CreateListItemFormComponent.css';
import Sidebar from "./Sidebar";
import Header from "./Header";
import { Map, APIProvider, useMapsLibrary, useMap } from "@vis.gl/react-google-maps";
import Autocomplete from '@mui/material/Autocomplete';

const S3_BUCKET = "renscobucket";
const REGION = "eu-north-1";
const google = window.google;
const IMAGE_FOLDER = "image";

const sustainableCategories = {
  'Geri Dönüştürülmüş Ürünler': [
    'Plastik bazlı geri dönüştürülmüş ürünler',
    'Metal bazlı geri dönüştürülmüş ürünler',
    'Cam bazlı geri dönüştürülmüş ürünler',
    'Kağıt/Karton bazlı geri dönüştürülmüş ürünler'
  ],
  'İkincil Hammaddeler': [
    'İşlenmiş ikincil plastik hammaddeler',
    'İşlenmiş ikincil metal hammaddeler',
    'İşlenmiş ikincil cam hammaddeler',
    'İşlenmiş ikincil kağıt/karton hammaddeler'
  ],
  'Endüstriyel Yan Ürünler': [
    'Proses yan ürünleri',
    'Endüstriyel kalıntılar',
    'İşlem artıkları'
  ],
  'Eko-Dostu Alternatif Ürünler': [
    'Biyobozunur ürünler',
    'Kompostlanabilir ürünler',
    'Yenilenebilir kaynaklı ürünler'
  ],
  'Yeniden Kullanılabilir Ürünler': [
    'Endüstriyel paketleme malzemeleri',
    'Konteynerler ve IBC\'ler',
    'Endüstriyel ekipmanlar'
  ]
};

const steps = [
  { title: 'İlan Türü', description: '', icon: <Description /> },
  { title: 'İlan Bilgileri', description: '', icon: <Description /> },
  { title: 'Kategori', description: '', icon: <Category /> },
  { title: 'Lojistik', description: '', icon: <LocalShipping /> },
  { title: 'Görseller', description: '', icon: <PhotoCamera /> },
  { title: 'Konum', description: '', icon: <LocationOn /> },
  { title: 'Önizleme', description: '', icon: <RateReview /> }
];

const CreateListItemFormComponent = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [formValues, setFormValues] = useState({
    selection: '',
    title: '',
    description: '',
    wastematerialCategory: '',
    category1: '',
    category2: '',
    category3: '',
    sustainableCategoryMain: '',
    sustainableCategorySubr: '',
    amount: '',
    unit: '',
    otherUnit: '',
    date_of_demanded_pickup: '',
    who_handle_logistics: '',
    images: [],
    photo_urls: [],
    address: '',
    latitude: null,
    longitude: null
  });

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [stepsConfirmed, setStepsConfirmed] = useState(Array(steps.length).fill(false));
  const [isEditing, setIsEditing] = useState(Array(steps.length).fill(false));
  const [isPreviewConfirmed, setIsPreviewConfirmed] = useState(false);
  const [successModalOpen, setSuccessModalOpen] = useState(false);
  const [showOtherUnit, setShowOtherUnit] = useState(false);

  const { token } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const token = new window.google.maps.places.AutocompleteSessionToken();
    setSessionToken(token);
    const autoService = new window.google.maps.places.AutocompleteService();
    setAutocompleteService(autoService);
  }, []);

  const [sessionToken, setSessionToken] = useState(null);

  const [autocompleteService, setAutocompleteService] = useState(null);

  const [placesService, setPlacesService] = useState(null);

  const [predictionResults, setPredictionResults] = useState([]);

  const [markerLocation, setMarkerLocation] = useState({
    lat: 51.509865,
    lng: -0.118092,
  });

  const places = useMapsLibrary('places');

  const handleAddressChange = (e) => {
    const { name, value } = e.target;
    fetchPredictions(value);
    setFormValues({ ...formValues, [name]: value });
  };

  const handleAutoChange = async (e, value) => {
    if (value === null || value === '') {
      return;
    }

    const { Place } = await window.google.maps.importLibrary("places");

    const place = new Place({
      id: value.place_id,
      fields: ["geometry.location"],
      sessionToken
    });

    await place.fetchFields({
      fields: ["displayName", "formattedAddress", "location"],
    });

    setFormValues({ ...formValues, address: place.formattedAddress, latitude: place.location.lat(), longitude: place.location.lng() });
    setMarkerLocation(place.location);
  };

  const fetchPredictions = async (inputValue) => {
    if (!autocompleteService || !inputValue) {
      setPredictionResults([]);
      return;
    }

    const request = { input: inputValue, sessionToken };
    const response = await autocompleteService.getPlacePredictions(request);
    setPredictionResults(response.predictions);
  }

  useEffect(() => {
    setFormValues({ ...formValues, photo_urls: formValues.images.map(imageFile => "https://" + S3_BUCKET + ".s3." + REGION + ".amazonaws.com/" + IMAGE_FOLDER + "/" + imageFile.name.replaceAll(/\s/g, '')) });
  }, [formValues.images]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues(prev => ({ ...prev, [name]: value }));
    setErrors(prev => ({ ...prev, [name]: '' }));
  };

  const onDrop = useCallback(acceptedFiles => {
    setFormValues(prev => ({
      ...prev,
      images: [...prev.images, ...acceptedFiles].slice(0, 5)
    }));
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const handleImageRemove = (index) => {
    setFormValues(prev => ({
      ...prev,
      images: prev.images.filter((_, i) => i !== index)
    }));
  };

  const uploadFiles = async () => {
    AWS.config.update({
      accessKeyId: process.env.REACT_APP_AWS_KEY,
      secretAccessKey: process.env.REACT_APP_AWS_KEY2,
    });
    const s3 = new AWS.S3({ params: { Bucket: S3_BUCKET }, region: REGION });

    for (let file of formValues.images) {
      const params = {
        Bucket: S3_BUCKET,
        Key: IMAGE_FOLDER + "/" + file.name.replace(/\s/g, ''),
        Body: file,
      };

      await s3.putObject(params).promise();
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      console.log(formValues);
      await uploadFiles();
      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/listItem`, formValues, {
        headers: {
          'Content-Type': 'application/json',
          'x-access-token': token,
        },
      });
      if (response.status === 201) {
        setSuccessModalOpen(true);
        resetForm();
      }
    } catch (error) {
      console.error('Error creating listing:', error);
      toast.error('Failed to create listing. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  const resetForm = () => {
    setFormValues({
      selection: '',
      title: '',
      description: '',
      wastematerialCategory: '',
      category1: '',
      category2: '',
      category3: '',
      sustainableCategoryMain: '',
      sustainableCategorySubr: '',
      amount: '',
      unit: '',
      otherUnit: '',
      date_of_demanded_pickup: '',
      who_handle_logistics: '',
      images: [],
      photo_urls: [],
      address: '',
      latitude: null,
      longitude: null
    });
    setActiveStep(0);
    setErrors({});
    setStepsConfirmed(Array(steps.length).fill(false));
    setIsEditing(Array(steps.length).fill(false));
    setIsPreviewConfirmed(false);
  };

  const validateStep = () => {
    const newErrors = {};
    switch (activeStep) {
      case 0:
        if (!formValues.selection) newErrors.selection = 'Please select a product type';
        break;
      case 1:
        if (!formValues.title) newErrors.title = 'Title is required';
        if (!formValues.description) newErrors.description = 'Description is required';
        if (formValues.selection === 'Atık' && !formValues.wastematerialCategory) {
          newErrors.wastematerialCategory = 'Malzeme kategorisi seçilmelidir';
        }
        break;
      case 2:
        if (formValues.selection === 'Atık') {
          if (!formValues.category1) newErrors.category1 = 'Category is required';
          if (!formValues.category2) newErrors.category2 = 'Sub-category is required';
          if (!formValues.category3) newErrors.category3 = 'Sub-sub-category is required';
        } else {
          if (!formValues.sustainableCategoryMain) newErrors.sustainableCategoryMain = 'Ana kategori seçilmelidir';
          if (!formValues.sustainableCategorySubr) newErrors.sustainableCategorySubr = 'Alt kategori seçilmelidir';
        }
        break;
      case 3:
        if (!formValues.amount) newErrors.amount = 'Quantity is required';
        if (!formValues.unit) newErrors.unit = 'Unit is required';
        if (formValues.unit === 'other' && !formValues.otherUnit) newErrors.otherUnit = 'Custom unit is required';
        if (!formValues.date_of_demanded_pickup) newErrors.date_of_demanded_pickup = 'Acquisition date is required';
        if (!formValues.who_handle_logistics) newErrors.who_handle_logistics = 'Logistics information is required';
        break;
      case 4:
        if (formValues.images.length === 0) newErrors.images = 'At least one image is required';
        break;
      case 5:
        if (!formValues.address) newErrors.address = 'Address is required';
        if (!formValues.latitude || !formValues.longitude) newErrors.location = 'Please select a location on the map';
        break;
      default:
        break;
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleNext = () => {
    if (validateStep()) {
      if (activeStep === steps.length - 1) {
        handleSubmit();
      } else {
        setActiveStep(prev => prev + 1);
      }
    }
  };

  const handleConfirm = () => {
    if (validateStep()) {
      const newConfirmed = [...stepsConfirmed];
      newConfirmed[activeStep] = true;
      setStepsConfirmed(newConfirmed);
      setIsEditing(prev => {
        const newEditing = [...prev];
        newEditing[activeStep] = false;
        return newEditing;
      });
    }
  };

  const handleEdit = () => {
    const newEditing = [...isEditing];
    newEditing[activeStep] = true;
    setIsEditing(newEditing);
    const newConfirmed = [...stepsConfirmed];
    newConfirmed[activeStep] = false;
    setStepsConfirmed(newConfirmed);
  };

  const handleConfirmPreview = () => {
    setIsPreviewConfirmed(true);
  };

  const handleEditInformation = () => {
    setIsPreviewConfirmed(false);
    setActiveStep(0);
  };

  const renderStepContent = (step) => {
    const isStepConfirmed = stepsConfirmed[step];
    const isStepEditing = isEditing[step];

    switch (step) {
      case 0:
        return (
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Button
                fullWidth
                variant="contained"
                onClick={() => setFormValues(prev => ({ ...prev, selection: 'Atık' }))}
                className={`selection-button ${formValues.selection === 'Atık' ? 'selected' : ''}`}
                disabled={isStepConfirmed && !isStepEditing}
              >
                <Recycling sx={{ fontSize: 48, marginBottom: 2 }} />
                Atık
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                fullWidth
                variant="contained"
                onClick={() => setFormValues(prev => ({ ...prev, selection: 'Sürdürülebilir Ürün' }))}
                className={`selection-button ${formValues.selection === 'Sürdürülebilir Ürün' ? 'selected' : ''}`}
                disabled={isStepConfirmed && !isStepEditing}
              >
                <Nature sx={{ fontSize: 48, marginBottom: 2 }} />
                Sürdürülebilir Ürün
              </Button>
            </Grid>
            {errors.selection && <Typography color="error">{errors.selection}</Typography>}
          </Grid>
        );
      case 1:
        return (
          <Box className="form-section">
            <TextField
              fullWidth
              variant="filled"
              label="İlan Başlığı"
              name="title"
              value={formValues.title}
              onChange={handleInputChange}
              required
              error={!!errors.title}
              helperText={errors.title}
              className="form-input"
              disabled={isStepConfirmed && !isStepEditing}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Title />
                  </InputAdornment>
                ),
              }}
            />

            {formValues.selection === 'Atık' && (
              <FormControl
                fullWidth
                variant="filled"
                required
                error={!!errors.wastematerialCategory}
                className="form-input"
              >
                <InputLabel>Malzeme Kategorisi</InputLabel>
                <Select
                  name="wastematerialCategory"
                  value={formValues.wastematerialCategory}
                  onChange={handleInputChange}
                  disabled={isStepConfirmed && !isStepEditing}
                  startAdornment={
                    <InputAdornment position="start">
                      <Category />
                    </InputAdornment>
                  }
                >
                  <MenuItem value="Plastik/Polimer Grubu">Plastik/Polimer Grubu</MenuItem>
                  <MenuItem value="Metal Grubu">Metal Grubu</MenuItem>
                  <MenuItem value="Cam Grubu">Cam Grubu</MenuItem>
                  <MenuItem value="Kağıt/Karton Grubu">Kağıt/Karton Grubu</MenuItem>
                  <MenuItem value="Tekstil Grubu">Tekstil Grubu</MenuItem>
                  <MenuItem value="Kimyasal Grup">Kimyasal Grup</MenuItem>
                  <MenuItem value="Organik Atık">Organik Atık</MenuItem>
                  <MenuItem value="Ahşap Grubu">Ahşap Grubu</MenuItem>
                  <MenuItem value="İnşaat Atığı">İnşaat Atığı</MenuItem>
                  <MenuItem value="Diğer">Diğer</MenuItem>
                </Select>
                {errors.wastematerialCategory && (
                  <Typography color="error" variant="caption">
                    {errors.wastematerialCategory}
                  </Typography>
                )}
              </FormControl>
            )}

            <TextField
              fullWidth
              variant="filled"
              label="İlan Açıklaması"
              name="description"
              value={formValues.description}
              onChange={handleInputChange}
              multiline
              rows={4}
              required
              error={!!errors.description}
              helperText={errors.description}
              className="form-input"
              disabled={isStepConfirmed && !isStepEditing}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Description />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        );
      case 2:
        return (
          <Box className="form-section">
            {formValues.selection === 'Atık' ? (
              <>
                <FormControl fullWidth required error={!!errors.category1} className="form-input">
                  <InputLabel>Atık Kodunuzu Seçiniz (Sektör Sınıflandırması)</InputLabel>
                  <Select
                    name="category1"
                    value={formValues.category1}
                    onChange={handleInputChange}
                    disabled={isStepConfirmed && !isStepEditing}
                    startAdornment={
                      <InputAdornment position="start">
                        <Category />
                      </InputAdornment>
                    }
                  >
                    {Object.entries(category_map)
                      .filter(([key]) => key.length === 2)
                      .sort((a, b) => parseInt(a[0]) - parseInt(b[0]))
                      .map(([key, value]) => (
                        <MenuItem key={key} value={key}>{value}</MenuItem>
                      ))}
                  </Select>
                  {errors.category1 && <Typography color="error">{errors.category1}</Typography>}
                </FormControl>
                {formValues.category1 && (
                  <FormControl fullWidth required error={!!errors.category2} className="form-input">
                    <InputLabel>Atık Kodunuzu Seçiniz (Proses Sınıflandırması)</InputLabel>
                    <Select
                      name="category2"
                      value={formValues.category2}
                      onChange={handleInputChange}
                      disabled={isStepConfirmed && !isStepEditing}
                      startAdornment={
                        <InputAdornment position="start">
                          <Category />
                        </InputAdornment>
                      }
                    >
                      {Object.entries(category_map)
                        .filter(([key]) => key.startsWith(formValues.category1) && key.length === 4)
                        .sort((a, b) => parseInt(a[0]) - parseInt(b[0]))
                        .map(([key, value]) => (
                          <MenuItem key={key} value={key}>{value}</MenuItem>
                        ))}
                    </Select>
                    {errors.category2 && <Typography color="error">{errors.category2}</Typography>}
                  </FormControl>
                )}
                {formValues.category2 && (
                  <FormControl fullWidth required error={!!errors.category3} className="form-input">
                    <InputLabel>Atık Kodunuzu Seçiniz (Atık Sınıflandırması)</InputLabel>
                    <Select
                      name="category3"
                      value={formValues.category3}
                      onChange={handleInputChange}
                      disabled={isStepConfirmed && !isStepEditing}
                      startAdornment={
                        <InputAdornment position="start">
                          <Category />
                        </InputAdornment>
                      }
                    >
                      {Object.entries(category_map)
                        .filter(([key]) => key.startsWith(formValues.category2) && key.length > 4)
                        .sort((a, b) => parseInt(a[0]) - parseInt(b[0]))
                        .map(([key, value]) => (
                          <MenuItem key={key} value={key}>{value}</MenuItem>
                        ))}
                    </Select>
                    {errors.category3 && <Typography color="error">{errors.category3}</Typography>}
                  </FormControl>
                )}
              </>
            ) : (
              <>
                <FormControl fullWidth required error={!!errors.sustainableCategoryMain} className="form-input">
                  <InputLabel>Ana Kategori Seçiniz</InputLabel>
                  <Select
                    name="sustainableCategoryMain"
                    value={formValues.sustainableCategoryMain}
                    onChange={(e) => {
                      handleInputChange(e);
                      setFormValues(prev => ({ ...prev, sustainableCategorySubr: '' }));
                    }}
                    disabled={isStepConfirmed && !isStepEditing}
                    startAdornment={
                      <InputAdornment position="start">
                        <Category />
                      </InputAdornment>
                    }
                  >
                    {Object.keys(sustainableCategories).map((category) => (
                      <MenuItem key={category} value={category}>
                        {category}
                      </MenuItem>
                    ))}
                  </Select>
                  {errors.sustainableCategoryMain && (
                    <Typography color="error" variant="caption">
                      {errors.sustainableCategoryMain}
                    </Typography>
                  )}
                </FormControl>

                {formValues.sustainableCategoryMain && (
                  <FormControl fullWidth required error={!!errors.sustainableCategorySubr} className="form-input">
                    <InputLabel>Alt Kategori Seçiniz</InputLabel>
                    <Select
                      name="sustainableCategorySubr"
                      value={formValues.sustainableCategorySubr}
                      onChange={handleInputChange}
                      disabled={isStepConfirmed && !isStepEditing}
                      startAdornment={
                        <InputAdornment position="start">
                          <Category />
                        </InputAdornment>
                      }
                    >
                      {sustainableCategories[formValues.sustainableCategoryMain].map((subCategory) => (
                        <MenuItem key={subCategory} value={subCategory}>
                          {subCategory}
                        </MenuItem>
                      ))}
                    </Select>
                    {errors.sustainableCategorySubr && (
                      <Typography color="error" variant="caption">
                        {errors.sustainableCategorySubr}
                      </Typography>
                    )}
                  </FormControl>
                )}
              </>
            )}
          </Box>
        );
      case 3:
        return (
          <Box className="form-section">
            <TextField
              fullWidth
              variant="filled"
              label="Miktar"
              name="amount"
              type="number"
              value={formValues.amount}
              onChange={handleInputChange}
              required
              error={!!errors.amount}
              helperText={errors.amount}
              className="form-input"
              disabled={isStepConfirmed && !isStepEditing}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Scale />
                  </InputAdornment>
                ),
                inputProps: { 
                 min: 0
                }
              }}              
            />
            <Typography variant="subtitle1" gutterBottom sx={{ mt: 2 }}>Birim Seçiniz</Typography>
            <Grid container spacing={2} sx={{ mb: 3 }}>
              <Grid item xs={4}>
                <Button
                  fullWidth
                  variant="contained"
                  onClick={() => {
                    setFormValues(prev => ({ ...prev, unit: 'ton', otherUnit: '' }));
                    setShowOtherUnit(false);
                  }}
                  className={`logistics-button ${formValues.unit === 'ton' ? 'selected' : ''}`}
                  disabled={isStepConfirmed && !isStepEditing}
                >
                  <Inventory2 />
                  Ton
                </Button>
              </Grid>
              <Grid item xs={4}>
                <Button
                  fullWidth
                  variant="contained"
                  onClick={() => {
                    setFormValues(prev => ({ ...prev, unit: 'm³', otherUnit: '' }));
                    setShowOtherUnit(false);
                  }}
                  className={`logistics-button ${formValues.unit === 'm³' ? 'selected' : ''}`}
                  disabled={isStepConfirmed && !isStepEditing}
                >
                  <Inventory2 />
                  Metreküp (m³)
                </Button>
              </Grid>
              <Grid item xs={4}>
                <Button
                  fullWidth
                  variant="contained"
                  onClick={() => {
                    setFormValues(prev => ({ ...prev, unit: 'other' }));
                    setShowOtherUnit(true);
                  }}
                  className={`logistics-button ${formValues.unit === 'other' ? 'selected' : ''}`}
                  disabled={isStepConfirmed && !isStepEditing}
                >
                  <Inventory2 />
                  Diğer
                </Button>
              </Grid>
            </Grid>

            {showOtherUnit && (
              <TextField
                fullWidth
                variant="filled"
                label="Diğer Birim (kg, lt, vb.)"
                name="otherUnit"
                value={formValues.otherUnit}
                onChange={(e) => {
                  handleInputChange({
                    target: {
                      name: 'otherUnit',
                      value: e.target.value
                    }
                  });
                }}
                required
                error={!!errors.otherUnit}
                helperText={errors.otherUnit}
                className="form-input"
                disabled={isStepConfirmed && !isStepEditing}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Inventory2 />
                    </InputAdornment>
                  ),
                }}
              />
            )}
            <TextField
              fullWidth
              variant="filled"
              label="Alım Tarihi"
              name="date_of_demanded_pickup"
              type="date"
              value={formValues.date_of_demanded_pickup}
              onChange={handleInputChange}
              required
              error={!!errors.date_of_demanded_pickup}
              helperText={errors.date_of_demanded_pickup}
              className="form-input"
              disabled={isStepConfirmed && !isStepEditing}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AccessTime />
                  </InputAdornment>
                ),
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />

            <Typography variant="subtitle1" gutterBottom>Lojistik süreci nasıl yürütülsün ?</Typography>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Button
                  fullWidth
                  variant="contained"
                  onClick={() => setFormValues(prev => ({ ...prev, who_handle_logistics: 'Buyer' }))}
                  className={`logistics-button ${formValues.who_handle_logistics === 'Buyer' ? 'selected' : ''}`}
                  disabled={isStepConfirmed && !isStepEditing}
                >
                  <LocalShipping />
                  Alıcı tarafından
                </Button>
              </Grid>
              <Grid item xs={4}>
                <Button
                  fullWidth
                  variant="contained"
                  onClick={() => setFormValues(prev => ({ ...prev, who_handle_logistics: 'Seller' }))}
                  className={`logistics-button ${formValues.who_handle_logistics === 'Seller' ? 'selected' : ''}`}
                  disabled={isStepConfirmed && !isStepEditing}
                >
                  <LocalShipping />
                  Satıcı tarafından
                </Button>
              </Grid>
              <Grid item xs={4}>
                <Button
                  fullWidth
                  variant="contained"
                  className="logistics-button"
                  disabled={true}
                >
                  <SupportAgent />
                  Rens'ten talep edin (Çok yakında!)
                </Button>
              </Grid>
            </Grid>
            {errors.who_handle_logistics && <Typography color="error">{errors.who_handle_logistics}</Typography>}
          </Box>
        );
      case 4:
        return (
          <Box className="form-section">
            <div {...getRootProps()} className={`dropzone ${isDragActive ? 'active' : ''}`}>
              <input {...getInputProps()} disabled={isStepConfirmed && !isStepEditing} />
              {
                isDragActive ?
                  <p>Drop the files here ...</p> :
                  <p>Görselleri buraya sürükleyin ya da görsel seçimi için tıklayınız.</p>
              }
            </div>
            <Grid container spacing={2}>
              {formValues.images.map((image, index) => (
                <Grid item xs={12} sm={6} md={4} key={index}>
                  <Card>
                    <CardMedia
                      component="img"
                      height="140"
                      image={URL.createObjectURL(image)}
                      alt={`Image ${index + 1}`}
                    />
                    <Box p={2}>
                      <Button
                        variant="outlined"
                        color="secondary"
                        onClick={() => handleImageRemove(index)}
                        fullWidth
                        disabled={isStepConfirmed && !isStepEditing}
                      >
                        Remove
                      </Button>
                    </Box>
                  </Card>
                </Grid>
              ))}
            </Grid>
            {errors.images && <Typography color="error">{errors.images}</Typography>}
          </Box>
        );
      case 5:
        return (
          <Box className="form-section">
            <Autocomplete
              onChange={handleAutoChange}
              id="free-solo-demo"
              freeSolo
              options={predictionResults}
              getOptionLabel={(option) =>
                typeof option === 'string' ? option : option.description
              }
              renderInput={(params) => <TextField {...params}
                fullWidth
                label="Address"
                name="address"
                variant="outlined"
                margin="normal"
                value={formValues.address}
                onChange={handleAddressChange}
              />}
            />
            <Box sx={{ height: '400px', width: '100%', mt: 2 }}>
              <CustomMap
                markerLocation={markerLocation}
                setMarkerLocation={setMarkerLocation}
                onLocationSelect={(lat, lng) => setFormValues(prev => ({ ...prev, latitude: lat, longitude: lng }))}
                disabled={isStepConfirmed && !isStepEditing}
              />
            </Box>
            {errors.location && <Typography color="error">{errors.location}</Typography>}
          </Box>
        );
      case 6:
        return (
          <Box className="form-section review-section">
            <Typography variant="h6" gutterBottom className="review-header">İlan Önizlemesi</Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Paper elevation={3} className="review-paper">
                  <Typography variant="subtitle1" className="review-subheader">İlan Bilgileri</Typography>
                  <Typography>İlan Türü: {formValues.selection}</Typography>
                  <Typography>İlan Başlığı: {formValues.title}</Typography>
                  <Typography>Açıklama: {formValues.description}</Typography>
                  {formValues.selection === 'Atık' && (
                    <Typography>Malzeme Kategorisi: {formValues.wastematerialCategory}</Typography>
                  )}
                </Paper>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Paper elevation={3} className="review-paper">
                  <Typography variant="subtitle1" className="review-subheader">Kategori Seçimi</Typography>
                  {formValues.selection === 'Atık' ? (
                    <>
                      <Typography>Atık Kodu: {category_map[formValues.category3]}</Typography>
                    </>
                  ) : (
                    <>
                      <Typography>Ana Kategori: {formValues.sustainableCategoryMain}</Typography>
                      <Typography>Alt Kategori: {formValues.sustainableCategorySubr}</Typography>
                    </>
                  )}
                </Paper>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Paper elevation={3} className="review-paper">
                  <Typography variant="subtitle1" className="review-subheader">Miktar ve Lojistik</Typography>
                  <Typography>Miktar: {formValues.amount} {formValues.unit === 'other' ? formValues.otherUnit : formValues.unit}</Typography>
                  <Typography>Alım Tarihi: {formValues.date_of_demanded_pickup}</Typography>
                  <Typography>Lojistik süreci: {formValues.who_handle_logistics}</Typography>
                </Paper>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Paper elevation={3} className="review-paper">
                  <Typography variant="subtitle1" className="review-subheader">Konum</Typography>
                  <Typography>Adres: {formValues.address}</Typography>
                </Paper>
              </Grid>
            </Grid>
            <Box sx={{ mt: 2 }}>
              <Typography variant="subtitle1" gutterBottom className="review-subheader">Görseller:</Typography>
              <Grid container spacing={2}>
                {formValues.images.map((image, index) => (
                  <Grid item xs={6} sm={4} md={3} key={index}>
                    <img src={URL.createObjectURL(image)} alt={`Product ${index + 1}`} className="review-image" />
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Box>
        );
      default:
        return 'Unknown step';
    }
  };
  return (
    <div className="app-layout">
      <Sidebar />
      <div className="main-content ml-64">
        <Header />
        <main className="main-form-area">
          <div className="create-list-item-form">
            <div className="form-container">
              <div className="form-sidebar">
                <div className="sidebar-steps">
                  {steps.map((step, index) => (
                    <div
                      key={step.title}
                      className={`sidebar-step ${index === activeStep ? 'active' : ''} ${stepsConfirmed[index] ? 'confirmed' : ''}`}
                      onClick={() => setActiveStep(index)}
                    >
                      <div className="step-number">{index + 1}</div>
                      <div className="step-content">
                        {step.icon}
                        <div className="step-text">
                          <Typography variant="subtitle1">{step.title}</Typography>
                          <Typography variant="body2" color="text.secondary">{step.description}</Typography>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="logo-container">
                  <img
                    src="/images/rensmarketlogo.png"
                    alt="Company Logo"
                    className="logo-image"
                  />
                </div>
              </div>
              <div className="form-content">
                <Paper className="form-paper">
                  <Box className="form-header">
                    <Typography variant="h4">{steps[activeStep].title}</Typography>
                    <IconButton onClick={handleNext} disabled={activeStep === steps.length - 1 || !stepsConfirmed[activeStep]}>
                      <ArrowForward />
                    </IconButton>
                  </Box>
                  {renderStepContent(activeStep)}
                  <Box className="form-navigation">
                    {activeStep < steps.length - 1 && (
                      <>
                        <Button
                          onClick={handleEdit}
                          variant="outlined"
                          color="primary"
                          className="modernform-button edit-button"
                          disabled={!stepsConfirmed[activeStep] || isEditing[activeStep]}
                        >
                          Düzenle
                        </Button>
                        <Button
                          onClick={handleConfirm}
                          variant="contained"
                          color="primary"
                          className="modernform-button confirm-button"
                          disabled={stepsConfirmed[activeStep] && !isEditing[activeStep]}
                        >
                          Onayla
                        </Button>
                      </>
                    )}
                    {activeStep === steps.length - 1 && (
                      <>
                        <Button
                          onClick={handleEditInformation}
                          variant="outlined"
                          color="primary"
                          className="modernform-button edit-information-button"
                          disabled={!isPreviewConfirmed}
                        >
                          Bilgileri düzenle
                        </Button>
                        <Button
                          onClick={handleConfirmPreview}
                          variant="contained"
                          color="primary"
                          className="modernform-button confirm-preview-button"
                          disabled={isPreviewConfirmed}
                        >
                          Kaydet
                        </Button>
                        <Button
                          onClick={handleSubmit}
                          variant="contained"
                          color="primary"
                          className="modernform-button submit-button"
                          disabled={!isPreviewConfirmed || isSubmitting}
                        >
                          {isSubmitting ? <CircularProgress size={24} /> : 'İlanı Oluştur'}
                        </Button>
                      </>
                    )}
                  </Box>
                </Paper>
              </div>
            </div>
          </div>
        </main>
      </div>
      <Modal
        open={successModalOpen}
        onClose={() => setSuccessModalOpen(false)}
        aria-labelledby="success-modal-title"
        aria-describedby="success-modal-description"
      >
        <Box className="success-modal">
          <CheckCircle className="success-icon" />
          <Typography id="success-modal-title" variant="h6" component="h2">
            You have successfully created your listing. Congratulations!
          </Typography>
          <Typography id="success-modal-description" sx={{ mt: 2 }}>
            Thank you for contributing to the circular economy. :)
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={() => navigate('/mylistings')}
            className="my-listings-button modernform-button"
          >
            İlanlarım
          </Button>
        </Box>
      </Modal>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={7000}
        onClose={() => setSnackbarOpen(false)}
        message='Listing successfully created'
      />
    </div>
  );
};

export default CreateListItemFormComponent;