import React from 'react'
import styles from './OfferItemComponent.module.css'

export default function OfferItemComponent({ user, offerMessage, listItemName }) {
  // Parse the offerMessage JSON string
  const offerDetails = JSON.parse(offerMessage);

  return (
    <div className={styles.card}>
      <div className={styles.cardContent}>
        <h2 className={styles.title}>{listItemName}</h2>
        
        <section className={styles.section}>
          <h3 className={styles.sectionHeader}>From:</h3>
          <p className={styles.value}>{user}</p>
        </section>
        
        <section className={styles.section}>
          <h3 className={styles.sectionHeader}>Offer Details:</h3>
          <ul className={styles.detailsList}>
            {Object.entries(offerDetails).map(([key, value]) => (
              <li key={key}>
                <span className={styles.label}>{key}:</span>  <span  className={styles.value}> {value}</span>
              </li>
            ))}
          </ul>
        </section>
      </div>
      
      <div className={styles.buttonContainer}>
        <button className={`${styles.button} ${styles.acceptButton}`}>
          Accept
        </button>
        <button className={`${styles.button} ${styles.rejectButton}`}>
          Reject
        </button>
      </div>
    </div>
  )
}